import { useAppDispatch } from '@/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';
import { FormInput } from '@/components/form-input/FormInput';
import { CloseIcon } from '@/components/icon-svg/IconSvg';

import { setAlertNotification } from '@/redux/globalReducer';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { NOTE_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { CATEGORY_PROCESS_OPTION } from '@/utils/constants/master-data/task/task';
import { Ii18n } from '@/utils/interfaces/i18n';
import { ITaskMasterAddEdit } from '@/utils/interfaces/masterdata';
import { addTask, editTask } from '@/utils/services/master';

import EditHistory from '../components/edit-history/EditHistory';
import { DEFAULT_TASK_MASTER, schemaForm } from './constant';

interface IAddEditModal {
  setFilter: () => void;
  wrapperRef: React.MutableRefObject<any>;
}

interface ITaskForm {
  id: string | undefined | null;
  category: string | undefined | null;
  managementName: string | undefined | null;
  displayName: string | undefined | null;
  taskContent: string | undefined | null;
  countryId: string | undefined | null;
  version?: string;
  createdDate?: string;
  createdName?: string;
  updatedDate?: string;
  updatedName?: string;
}

export const AddEditTaskMaster = forwardRef(({ wrapperRef, setFilter }: IAddEditModal, ref) => {
  const { t }: Ii18n = useTranslation();
  const dispatch = useAppDispatch();
  const [showModalAndEdit, setShowModalAndEdit] = useState<boolean>(false);
  const [task, setTask] = useState<ITaskForm>();
  const [titleAddEdit, setTitleAddEdit] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { countryOptions } = useOptionsGlobal();
  const [mode, setMode] = useState<'create' | 'edit' | undefined>(undefined);

  const taskForm = useForm<ITaskForm>({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(schemaForm),
    defaultValues: DEFAULT_TASK_MASTER
  });

  const handleOpenEditModal = (row: any) => {
    const dataEdit = {
      id: row.id,
      category: row.type,
      managementName: row.managementCode,
      displayName: row.name,
      taskContent: row.content ?? null,
      countryId: row.countryId,
      version: row.version,
      createdName: row.createdName,
      createdDate: row.createdDate,
      updatedName: row.updatedName,
      updatedDate: row.updatedDate
    };
    taskForm.reset({ ...dataEdit });
    setTask({ ...dataEdit });
    setShowModalAndEdit(true);
    setTitleAddEdit(t('master_data:title_edit_task') || '');
    setMode('edit');
  };

  const handleOpenCreateModal = () => {
    const dataCreate = {
      id: null,
      category: null,
      managementName: null,
      displayName: null,
      taskContent: null,
      countryId: null
    };
    taskForm.reset({ ...dataCreate });
    setTask({ ...dataCreate });
    taskForm.clearErrors();
    setShowModalAndEdit(true);
    setTitleAddEdit(t('master_data:create_new_task') || '');
    setMode('create');
  };

  const handleSubmitForm: SubmitHandler<any> = async (data) => {
    let payload: ITaskMasterAddEdit = {
      name: data.displayName,
      type: data.category,
      managementCode: data.managementName,
      countryId: data.countryId,
      content: data.taskContent
    };
    const isEditMode = !!(taskForm.getValues('id') || taskForm.formState.defaultValues?.id);
    if (isEditMode) {
      payload = {
        ...payload,
        id: taskForm.getValues('id') || '',
        version: Number(taskForm.getValues('version')) || 1
      };
    }
    try {
      setLoading(true);
      isEditMode ? await editTask(payload) : await addTask(payload);
      setShowModalAndEdit(false);
      dispatch(
        setAlertNotification({
          show: true,
          type: 'success',
          position: 'top',
          message: t('common:MSG_026', { name: t('master_data:task_todo') || '' })
        })
      );
      taskForm.reset();
      taskForm.clearErrors();
      setFilter();
    } catch (error: any) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCreateModal = useCallback(async () => {
    if (taskForm.formState.isDirty) {
      const title = t('common:MSG_C_002:title');
      const msg = t('common:MSG_C_002:description');
      const showPopup = await showBasePopup({ title, msg, type: 'discard' });
      setShowModalAndEdit(showPopup !== 'confirm');
    } else setShowModalAndEdit(false);
  }, [taskForm.formState.isDirty]);

  const categoryOption = useMemo(
    () =>
      CATEGORY_PROCESS_OPTION.map((item: any) => ({
        ...item,
        label: t(item.label)
      })),
    [CATEGORY_PROCESS_OPTION]
  );

  const capitalizeFirstLetter = (stringText: string) => {
    return stringText.charAt(0).toUpperCase() + stringText.slice(1);
  };

  const { countryId, managementName } = taskForm.watch();

  useEffect(() => {
    if (!managementName) return;
    taskForm.trigger('managementName');
  }, [countryId, managementName]);

  useImperativeHandle(ref, () => ({
    handleOpenCreateModal,
    handleOpenEditModal
  }));

  const disableSaveBtn = () => {
    return (mode !== 'create' && !taskForm.formState.isDirty) || taskForm.formState.isSubmitting || loading;
  };

  return (
    <BaseModal
      onCancel={handleCloseCreateModal}
      maskClosable={false}
      openModal={showModalAndEdit}
      closable
      destroyOnClose
      width={747}
      className='my-3'
      getContainer={() => wrapperRef?.current ?? document.body}
      title={titleAddEdit}
      closeIcon={<CloseIcon className='w-[24px] h-[24px]' onClick={handleCloseCreateModal} />}
      contentElement={
        <FormProvider {...taskForm}>
          <div className='space-y-[12px]'>
            <FormInputSelect
              name='category'
              required
              placeholderTx={`${t('common:MSG_001_select', { field: t('master_data:task:category') })}`}
              labelTx={t('master_data:task:category')}
              options={categoryOption}
              onChange={(val: any) => {
                const option = categoryOption?.find((c: any) => c.value === val);
                taskForm.setValue('category', option.value);
              }}
            />
            <FormInput
              defaultValue={taskForm.getValues(`managementName`) || task?.managementName || undefined}
              name='managementName'
              required
              labelTx='master_data:task:management_name'
              placeholderTx={`${t('common:MSG_001_textbox', { field: t('master_data:task:management_name') })}`}
            />
            <FormInput
              defaultValue={taskForm.getValues(`displayName`) || task?.displayName || undefined}
              name='displayName'
              required
              labelTx='master_data:task:display_name'
              placeholderTx={`${t('common:MSG_001_textbox', { field: t('master_data:task:display_name') })}`}
            />
            <FormInputSelect
              disabled={!!task?.id}
              searchMaxLength={128}
              name='countryId'
              options={countryOptions}
              required
              labelTx={capitalizeFirstLetter(t('master_data:task:country_name'))}
              placeholderTx={t('common:MSG_001_select', { field: t('master_data:task:country_name') }) ?? ''}
            />
            <div>
              <EditorWithCounter
                name='taskContent'
                required
                limit={NOTE_MAX_LENGTH}
                label={'master_data:task:task_content'}
                defaultValue={taskForm.getValues(`taskContent`) || task?.taskContent || undefined}
                placeholder={`${t('common:MSG_001_textbox', { field: t('master_data:task:task_content') })}`}
                editorWrapperProps={{ className: '[&>.ql-container>.ql-editor]:min-h-[200px] box-border' }}
                styleCounterLabel='text-gray3'
              />
            </div>
            {taskForm.getValues('id') && <EditHistory data={task ?? {}} />}
            <div className='flex gap-[16px] justify-end'>
              <BaseButton disabled={loading} type='tertiary' size='medium' onClick={handleCloseCreateModal}>
                {t('master_data:btn_cancel')}
              </BaseButton>
              <BaseButton disabled={disableSaveBtn()} type='primary' size='medium' onClick={taskForm.handleSubmit(handleSubmitForm)}>
                {t('master_data:btn_keep')}
              </BaseButton>
            </div>
          </div>
        </FormProvider>
      }
    />
  );
});
