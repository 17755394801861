export const API = {
  GET_PROJECT_FILE_LIST: (projectId: string) => `prj/projects/${projectId}/files/search`,
  GET_PROJECT_INFO: (projectId: string) => `/prj/projects/${projectId}/info`,
  GET_PROJECT_PUBLIC_INFO: (projectId: string) => `/prj/projects/${projectId}/check`,
  GET_PROJECT_EXTERNAL: (projectId: string) => `/prj/projects/${projectId}/external`,
  CREATE_PROJECT_EXTERNAL: (type: string) => `/prj/projects/external/${type}`,
  UPDATE_PROJECT_EXTERNAL: (type: string) => `/prj/projects/external/${type}`,
  GET_PROJECT_DETAIL: (projectId: string) => `/prj/projects/${projectId}/detail`,
  GET_TEMPLATE_INFO: (templateId: string) => `/tmpl/templates/${templateId}`,
  GET_PHRASES: () => '/prj/phrases',
  GET_COMMENT_LIST: (projectId: string) => `/prj/projects/${projectId}/comments`,
  GET_REQUIRE_DOCUMENT_PROJECT: (projectId: string) => `/prj/projectrequireddocument/${projectId}`,
  GET_INTERNAL_DOCUMENT_PROJECT: (projectId: string) => `/prj/projectinternaldocument/${projectId}`,
  GET_DETAIL_TASK: (idProject: string, idTask: string) => (idProject && idTask ? `/prj/projects/${idProject}/task/${idTask}` : ''),
  UPDATE_DEADLINE_TASK_DETAIL: (idProject: string, idTask: string) => `/prj/projects/${idProject}/task/${idTask}/deadline`,
  UPDATE_PIC_TASK_DETAIL: (idProject: string, idTask: string) => `/prj/projects/${idProject}/task/${idTask}/pic`,
  UPDATE_STATUS_TASK_DETAIL: (idProject: string, idTask: string) => `/prj/projects/${idProject}/task/${idTask}/status`,
  GET_DOCUMENT_LIST: (projectId: string) => `/prj/projectDocuments/${projectId}/search`,
  EXPORT_DOCUMENT_LIST: (projectId: string) => `/prj/projectDocuments/${projectId}/export`,
  // Announcement
  GET_ANNOUNCEMENT_LIST: (projectId: string) => `/prj/projects/${projectId}/announcements/search`,
  GET_ANNOUNCEMENT_DETAIL: (announcementId: string) => `/prj/projects/announcements/${announcementId}`,
  // Questionnaire
  GET_PROJECT_QUESTIONNAIRE_LIST: (projectId: string) => `/prj/questionnaire/${projectId}`,
  GET_PROJECT_QUESTIONNAIRE_FORM: (projectId: string) => `/prj/questionnaire/${projectId}/forms`,
  GET_PROJECT_QUESTIONNAIRE_FORM_DETAIL: (projectId: string, formId: string) => `/prj/questionnaire/${projectId}/object/${formId}/detail`,
  GET_PROJECT_QUESTIONNAIRE_FORM_INFO: (projectId: string, formId: string) => `/prj/questionnaire/${projectId}/object/${formId}/info`,
  CHANGE_QUESTIONNAIRE_TEMPLATE: (projectId: string, formId: string) => `prj/questionnaire/${projectId}/forms/${formId}`,
  PUT_QUESTIONNAIRE_SAVE: (projectId: string, formId: string) => `/prj/questionnaire/${projectId}/object/${formId}/save`,
  PUT_QUESTIONNAIRE_TRANSLATE: (projectId: string, formId: string) => `/prj/questionnaire/${projectId}/translate/${formId}`,
  POST_QUESTIONNAIRE_LOCK: (projectId: string, formId: string) => `/prj/questionnaire/${projectId}/object/${formId}/lock`,
  POST_QUESTIONNAIRE_UNLOCK: (projectId: string, formId: string) => `/prj/questionnaire/${projectId}/object/${formId}/unlock`,
  PUT_QUESTIONNAIRE_UNLOCK_SUBMIT: (projectId: string, formId: string) => `/prj/questionnaire/${projectId}/object/${formId}/unlockEntity`,
  POST_QUESTIONNAIRE_EXPORT_CSV: (projectId: string, formId: string) => `/prj/questionnaire/${projectId}/object/${formId}/exportcsv`,
  GET_QUESTIONNAIRE_ANSWER: `/prj/questionnaire/{projectId}/question/{questionId}/answer`,
  CREATE_TASK_OF_PROJECT: '/prj/projecttask',
  UPDATE_TASK_OF_PROJECT: (idTask: string) => `/prj/projecttask/${idTask}`,
  GET_COMMENTS_TASK_OF_PROJECT: (projectId: string, projectProcessId: string, taskId: string) =>
    `prj/projectTask/${projectId}/${projectProcessId}/${taskId}/comment`,
  POST_COMMENT_TASK_OF_PROJECT: (projectId: string, projectProcessId: string, taskId: string) =>
    `prj/projectTask/${projectId}/${projectProcessId}/${taskId}/comment`,
  UPDATE_COMMENT_TASK_OF_PROJECT: (projectId: string, projectProcessId: string, taskId: string) =>
    `prj/projectTask/${projectId}/${projectProcessId}/${taskId}/comment/{commentId}`,
  GET_USERS_INFO: '/usr/users/ids/info',
  READ_COMMENTS_TASK_OF_PROJECT: (taskId: string) => `prj/projectTask/${taskId}/comment/mark-read`,
  CREATE_SCENARIO_C: '/prj/projects/external/scenario-c',
  CREATE_SCENARIO_D: '/prj/projects/external/scenario-d',
  CREATE_SCENARIO_E: '/prj/projects/external/scenario-e',
  UPLOAD_FILES: '/sta/files/up-multiple/attachments',
  GET_STAKEHOLDERS_BY_PROJECT: (projectId: string) => `/prj/projects/${projectId}/stakeholders`,
  GET_TRAVEL_TYPE_OPTIONS: '/prj/traveltypes',
  GET_USERS_MOT_PIC: (isShowInactive: boolean = false) => `/usr/Users/mot-pic?includeInactive=${isShowInactive}`,
  GET_TASK_PICS: '/usr/users/pics',
  GET_USER_DETAIL: (userId: string) => `/usr/users/${userId}`,
  GET_MASTER_COUNTRIES: '/mst/countries',
  GET_MASTER_COUNTRIES_PUBLIC: '/mst/share/countries',
  GET_DOCUMENT_NAME_LIST: `/mst/documents/search`,
  UPDATE_DOCUMENT_LIST: (projectId: string) => `/prj/projectDocuments/${projectId}/upsert`,
  GET_DOCUMENT_OPTIONS: `/mst/Documents`,
  GET_PROJECT_STATUS_LIST: '/prj/status',
  GET_TASKS_OF_ALL_PROJECTS: 'prj/ProjectTask/search',
  EXTERNAL_QUESTION_VALIDATE_TOKEN: '/prj/questionnaireshared/validate-token',
  EXTERNAL_QUESTION_AUTHENTICATE: '/prj/questionnaireshared/authenticate',
  GET_EXTERNAL_QUESTION_LIST: (token: string) => `/prj/questionnaireshared/${token}`,
  GET_EXTERNAL_QUESTION_DETAIL: (token: string, id: string) => `/prj/questionnaireshared/${token}/object/${id}/detail`,
  PUT_EXTERNAL_QUESTION_EDIT: (token: string, id: string) => `/prj/questionnaireshared/${token}/object/${id}/save`,
  GET_GENERATE_QUESTION_TOKEN: (id: string) => `/prj/questionnaire/${id}/generate-token`,
  GET_ROLES: () => `/usr/roles`,
  GET_USER_INFO: (parentPath?: string) => `${parentPath ?? ''}/usr/users/info`,
  // Notice
  GET_NOTICE: 'usr/notify/search',
  POST_HIDDEN_NOTICE: 'usr/notify/hidden',
  POST_ASSIGN_NOTICE: 'usr/notify/assign',
  POST_COUNT_UNREAD_NOTICE: 'usr/notify/count-unread',
  POST_READED_NOTICE: 'usr/notify/readed',
  GET_ASSIGN_NOTICE: 'usr/notify/assign',
  GET_FLAG_NOTICE: 'usr/notify/flag',
  PUT_ON_OFF_ALERT: 'usr/userSettings/alert-config',
  READ_TUTORIAL: '/usr/users/readed-tutorial'
};
