import { Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';

import CloseIcon from '../../assets/icons/CloseIcon.svg';

import './BaseModal.scss';

export interface IBaseModalType extends ModalProps {
  openModal: boolean;
  width?: number;
  className?: string;
  closeable?: boolean;
  contentElement?: ReactNode;
  footerElement?: ReactNode;
}

const BaseModal = (props: IBaseModalType) => {
  const { openModal, closeable, className, width, contentElement, footerElement, title, ...rest } = props;

  return (
    <Modal
      closeIcon={
        <div>
          <CloseIcon className='mt-[5px]' />
        </div>
      }
      centered
      open={openModal}
      title={<div className='title-20 text-start mb-3'>{title}</div>}
      className={classNames('base-modal', className)}
      closable={closeable}
      width={width ?? 565}
      footer={footerElement ?? null}
      {...rest}
    >
      {contentElement}
    </Modal>
  );
};

export default BaseModal;
