import { AxiosResponse } from 'axios';

import { DeleteDataReq } from '@/pages/announcement-management/view/models';
import { ProjectMemoReq } from '@/pages/project-management/memo/model';

import { ITaskPic } from '@utils/interfaces/user';

import { axiosInstance } from '../../config/http';
import { API } from '../constants/Apis';
import { AXIOS_TIMEOUT_UPLOAD, DEFAULT_PERPAGE } from '../constants/AppConstants';
import { IValidationEmail } from '../interfaces/Project';
import { IParams, IParamsPostCommentTodo } from '../interfaces/service';

export const getUsers = async () => {
  const res = await axiosInstance.get(`/get-user`);
  return res;
};

export const getProjectList = async (params: any) => {
  return axiosInstance.post(`/prj/projects/search`, params);
};

export const getProjectDetail = async (id: string) => {
  const res = await axiosInstance.get(`/get-project-detail/${id}`);
  return res.data;
};

export const deleteProject = async (params?: { projects: { id: string; version: string }[] }) => {
  const res = await axiosInstance.delete(`/prj/projects`, {
    data: params
  });
  return res;
};

export const requestAddProject = async (params: any) => {
  const res = await axiosInstance.post(`/add-project`, params);
  return res;
};
export const updateProject = async (id: string, params: any) => {
  const res = await axiosInstance.patch(`/projects/${id}`, params);
  return res;
};

export const updateMemo = async (id: string, params: any) => {
  const res = await axiosInstance.put(`/prj/projects/${id}/memo`, params);
  return res;
};

export const updateShortMemo = async (id: string, params: any) => {
  const res = await axiosInstance.put(`/prj/projects/${id}/shortmemo`, params);
  return res;
};
export const getApplicantList = async () => {
  const res = await axiosInstance.get(`/prj/ProjectApplicants`);
  return res;
};

export const getSupportTypeList = async () => {
  const res = await axiosInstance.get(`/prj/SupportTypes`);
  return res;
};

export const getRequestTypeList = async () => {
  const res = await axiosInstance.get(`/prj/RequestTypes`);
  return res;
};

export const validationEmail = async (params: IValidationEmail) => {
  const res = await axiosInstance.post(`/usr/users/emails/info`, params);
  return res;
};

export const getProjectLanguageList = async () => {
  const res = await axiosInstance.get(`/prj/ProjectLanguages`);
  return res;
};

export const getTravelerList = async () => {
  const res = await axiosInstance.get(`/prj/TravelTypes/`);
  return res;
};

export const getCompanyList = async (PageIndex: number = 0, PageSize: number = DEFAULT_PERPAGE, sorts: string = `createdDate=asc`, filter?: any) => {
  const res = await axiosInstance.post(`/mst/companies/search`, { PageIndex, PageSize, sorts, filter });
  return res;
};

export const getAllCompany = async () => {
  const res = await axiosInstance.get(`/mst/companies`);
  return res;
};

export const getAllTaskProject = async <T extends {}>(params: T) => {
  return axiosInstance.post(`/prj/tasklist/search`, params);
};

export const createOrEditCompany = async (params: { id?: string | number; name: string }) => {
  if (!params?.id) {
    const res = await axiosInstance.post(`/mst/companies`, params);
    return res;
  } else {
    const res = await axiosInstance.put(`/mst/companies`, params);
    return res;
  }
};

export const deleteCompanies = async (data: { ids: { id: string; version: string }[] }) => {
  const res = await axiosInstance.delete(`/mst/companies`, { data });
  return res;
};

export const getStatus = async () => {
  const res = await axiosInstance.get(`/tmpl/processes`);
  return res;
};

export const getProjectById = async (id: string) => {
  const res = await axiosInstance.get(`/prj/Projects/${id}`);
  return res.data;
};

export const getProjectArrangementTargets = async () => {
  const res = await axiosInstance.get(`/prj/ArrangementTargets`);
  return res;
};

export const createBasicInformation = async (params: any) => {
  const res = await axiosInstance.post(`/prj/Projects`, params);
  return res;
};

export const updateBasicInformation = async (id: string, params: any) => {
  const res = await axiosInstance.put(`/prj/Projects/${id}`, params);
  return res;
};

export const updateProjectStatus = async (projectId: string, statusId: string, version?: number) => {
  const res = await axiosInstance.put(`/prj/Projects/${projectId}/${version}/status/${statusId}`);
  return res;
};

export const changeTemplate = async (projectId: string, templateId: string, version: number) => {
  const res = await axiosInstance.put(
    `/prj/Projects/${projectId}/templates/${templateId}/${version}`,
    {},
    {
      timeout: AXIOS_TIMEOUT_UPLOAD
    }
  );
  return res;
};

export const getScheldulesByProjectId = async (projectId: string) => {
  const res = await axiosInstance.get(`/prj/Projects/${projectId}/schedules`);
  return res;
};

export const updateScheldulesProject = async (
  projectId: string,
  params: {
    Schedules: any[];
    SchedulesDeleted: string[];
  }
) => {
  const res = await axiosInstance.put(`/prj/Projects/${projectId}/schedules`, params);
  return res;
};

export const getProjectTasks = async (projectId?: string, params?: any) => {
  const res = await axiosInstance.get(`/prj/projects/${projectId}/tasks`, params);
  return res;
};

export const updateProjectTask = async (
  projectId: string,
  params: {
    projectTasks: any[];
    tasksDeleted: any[];
  }
) => {
  const res = await axiosInstance.put(`/prj/Projects/${projectId}/tasks`, params);
  return res;
};

export const updateArrangementTargetCode = async (projectId?: string, targetId?: string, version?: number) => {
  const res = await axiosInstance.put(`/prj/Projects/${projectId}/${version}/target/${targetId}`);
  return res;
};

export const getProjectDocuments = async (projectId: string) => {
  const res = await axiosInstance.get(`/prj/Projects/${projectId}/documents`);
  return res;
};

export const updateProjectDocuments = async (projectId: string, body: any) => {
  const res = await axiosInstance.put(`/prj/Projects/${projectId}/documents`, body);
  return res;
};

export const updateProjectAssignPic = async (projectId: string, body: any) => {
  const res = await axiosInstance.put(`/prj/Projects/${projectId}/assign`, body);
  return res;
};

export const getCustomerRequestByProject = async (projectId: string, body: any) => {
  const res = await axiosInstance.post(`/prj/Projects/customer-request`, { projectId, ...body });
  return res;
};

export const getCustomerRequestById = async (projectId: string) => {
  const res = await axiosInstance.get(`/prj/CustomerRequest/${projectId}`);
  return res;
};

export const getAllCommentByRequestId = async (requestId: string) => {
  const res = await axiosInstance.get(`/prj/CustomerRequest/${requestId}/comments`);
  return res;
};

export const commentToCustomerRequest = async (payload: object) => {
  const res = await axiosInstance.post(`/prj/CustomerRequest/comments`, payload);
  return res;
};

export const updateComment = async (commentId: string, payload: object) => {
  const res = await axiosInstance.put(`/prj/CustomerRequest/comments/${commentId}`, payload);
  return res;
};

export const markViewAllComent = async (payload: object) => {
  const res = await axiosInstance.post(`/prj/CustomerRequest/comments/views`, payload);
  return res;
};

export const getAttachmentByIdCustomerRequest = async (customerRequestId: string) => {
  const res = await axiosInstance.get(`/prj/CustomerRequest/${customerRequestId}/attachments`);
  return res;
};

export const deleteAttachmentByIdFile = async (idFile: string) => {
  const res = await axiosInstance.delete(`/prj/CustomerRequest/attachments/${idFile}`);
  return res;
};

export const deleteCustomerRequest = async (params?: { ids: (string | number)[] }) => {
  const res = await axiosInstance.delete(`/prj/CustomerRequest`, {
    data: params
  });
  return res;
};

export const updateCustomerRequestByProject = async (customerRequestId: string, body: any) => {
  const res = await axiosInstance.put(`/prj/CustomerRequest/${customerRequestId}`, body);
  return res;
};

export const getCreatorCustomerRequest = async (projectId: string) => {
  const res = await axiosInstance.get(`/prj/Projects/${projectId}/customer-request/creator`);
  return res;
};

export const getTaskPicCustomerRequest = async (projectId: string) => {
  const res = await axiosInstance.get(`/prj/Projects/${projectId}/customer-request/pic`);
  return res;
};

export const getUserInProject = async (projectId: string) => {
  const res = await axiosInstance.get(`/prj/Projects/${projectId}/users`);
  return res;
};

export const getQuestionByProjectId = async (id: string) => {
  const res = await axiosInstance.get(`/prj/projects/${id}/questionnaire`);

  return res;
};

export const getSectionDetails = async (params: { projectId: string; sectionId: string; objectId: string }) => {
  const { projectId, sectionId, objectId } = params;
  const res = await axiosInstance.get(`/prj/projects/${projectId}/questionnaire/${sectionId}/objects/${objectId}`, {
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return res.data;
};

export const upsertQuestionnaireProject = async (body: any) => {
  const res = await axiosInstance.put(`/prj/projects/questionnaire`, body, {
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return res;
};

export const getPhrase = async (params?: any) => {
  return axiosInstance.post(`/prj/phrases/search`, params);
};

export const lockQuestionnaireProject = async (id: any) => {
  const res = await axiosInstance.post(`/prj/projects/${id}/lock-questionnare`);
  return res;
};

export const createCustomerRequest = async (params: any) => {
  const res = await axiosInstance.post(`/prj/CustomerRequest`, params);
  return res;
};

export const getAllQuestionProject = async (id: string) => {
  const res = await axiosInstance.get(`/prj/projects/${id}/questionnaire-detail`, {
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return res;
};

export const getQuestionActiveTabProject = async (id: string, objectId: string) => {
  const res = await axiosInstance.get(`/prj/projects/${id}/objects/${objectId}/questionnaires`, {
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return res;
};

export const updateDeadlineProject = async (id: string, params: any) => {
  const res = await axiosInstance.put(`/prj/projects/${id}/deadline`, params);
  return res;
};

export const deleteCommentDraft = async (id: string) => {
  const res = await axiosInstance.delete(`/prj/CustomerRequest/comments/${id}`);
  return res;
};

export const searchTasksByProjectId = async (projectId: string, params: IParams) => {
  const res = await axiosInstance.post(`/prj/projects/${projectId}/tasks/search`);
  return res;
};

export const updateStatusCustomerRequest = async (id: string, params: { status: string; version: number }) => {
  const res = await axiosInstance.put(`/prj/CustomerRequest/${id}/status`, params);
  return res;
};

export const getCommentsTodo = async (projectId: string, taskId: string) => {
  const res = await axiosInstance.get(`/prj/projects/${projectId}}/tasks/${taskId}/comments`);
  return res;
};

export const updateStatusCommentTodo = async (taskId: string, params: { taskCommendIds: string[] }) => {
  const res = await axiosInstance.put(`/prj/projects/tasks/${taskId}/comments/markReader`, params);
  return res;
};

export const postCommentTodo = async (params: IParamsPostCommentTodo) => {
  const res = await axiosInstance.post(`/prj/projects/tasks/comments`, params);
  return res;
};

// project basic info
export const getProjectInfo = async (id: string) => {
  return axiosInstance.get(`/prj/projects/${id}/info`);
};
export const getProjectCheck = async (id: string) => {
  return axiosInstance.get(`/prj/projects/${id}/check`);
};
export const getProjectExternal = async (id: string) => {
  return axiosInstance.get(`/prj/projects/${id}/external`);
};
export const getProjectStatus = async () => {
  return axiosInstance.get('prj/status');
};

export const postRegisterProjectInternal = async (data: any) => {
  return axiosInstance.post('/prj/projects/internal', data);
};

export const putProjectInternal = async (id: string, params: any) => {
  const res = await axiosInstance.put(`/prj/projects/${id}/internal`, params);
  return res;
};

// announcement api
export const getAnnouncementById = async (id: string) => {
  return axiosInstance.get(`/prj/projects/announcements/${id}`);
};

export const deleteAnnouncement = async (projectId: string, data: DeleteDataReq) => {
  return axiosInstance.delete(`/prj/projects/${projectId}/announcements`, { data });
};

export const markReadAnnouncement = async (projectId: string, announcementId: string) => {
  return axiosInstance.put(`/prj/projects/${projectId}/announcements/${announcementId}/mark-read`);
};

// project detail
export const getDetailProject = async (id: string) => {
  return axiosInstance.get(`/prj/projects/${id}/detail`);
};

// project memo
export const getDetailProjectMemo = async (id: string) => {
  return axiosInstance.get(`/prj/projectmemos/${id}`);
};

export const addProjectMemo = async (id: string, data: ProjectMemoReq) => {
  return axiosInstance.post(`/prj/projectmemos/${id}`, data);
};

export const updateProjectMemo = async (id: string, data: ProjectMemoReq) => {
  return axiosInstance.put(`/prj/projectmemos/${id}`, data);
};

export const getSupportTypeMemoList = async () => {
  return axiosInstance.get(`/prj/SupportTypes/memo`);
};

// batch registration project
export const validateDataExcel = async (file: FormData) => {
  const token = (await localStorage.getItem('access_token')) ?? '';
  return axiosInstance.post('/prj/projects/validate-data/excel', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  });
};

export const batchRegistrationProject = async (file: FormData) => {
  const token = (await localStorage.getItem('access_token')) ?? '';
  return axiosInstance.post('/prj/projects/save-data/excel', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  });
};

export interface IProjectPublicInfo {
  id: string;
  code: string;
  isDraft: boolean;
  isPublished?: boolean;
}

export const getProjectPublicInfo = async (id: string): Promise<AxiosResponse<IProjectPublicInfo, any>> => {
  return axiosInstance.get(API.GET_PROJECT_PUBLIC_INFO(id));
};

export const getDetailTask = async (projectId: string, taskId: string) => {
  return axiosInstance.get(`/prj/projects/${projectId}/task/${taskId}`);
};

export const fetchDataHandleError404 = async (url: string, method: string) => {
  if (method === 'GET') return axiosInstance.get(url);
  return axiosInstance.post(url);
};

export const exportPdf = async (projectId: string) => {
  return axiosInstance.post(`/prj/projects/${projectId}/exportpdf`);
};

// task of project
export const getDetailTaskOfProject = async (idProject: string, idTask: string) => {
  return axiosInstance.get(`/prj/projects/${idProject}/task/${idTask}`);
};

export const getShareToken = (projectId: string) => {
  return axiosInstance.get(`/prj/Questionnaire/${projectId}/generate-token`);
};

// get api project have request account
export const getProjectInfoProject = (referenceId: string) => {
  return axiosInstance.get(`/prj/projects/info-project/${referenceId}`);
};

export const getUsersInfo = async (ids: string[]): Promise<AxiosResponse<ITaskPic[]>> => {
  return axiosInstance.post(API.GET_USERS_INFO, { ids });
};
