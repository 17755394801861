import { DefaultOptionType } from 'antd/es/select';
import { isEmpty } from 'lodash';
import React from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TASK_TYPE } from '@pages/project-management/detail/constant';
import { DEFAULT_FILTER_FORM } from '@pages/project-management/project-list-v2/constant';
import { IFormFilter, IPrimary } from '@pages/project-management/project-list-v2/models';
import { IStatus } from '@pages/project-management/project-list/models';

import InfinityFormSelect from '@/components/form-select/InfinityFormSelect';
import { BaseButton } from '@components/base-button/BaseButton';
import { FormInputDate } from '@components/form-input-date/FormInputDate';
import { FormSelect } from '@components/form-select/FormSelect';

import useAuthorization from '@hooks/useAuthorization';
import useOptionsGlobal from '@hooks/useOptionsGlobal';

import { API } from '@/utils/constants/Apis';
import { Ii18n } from '@utils/interfaces/i18n';
import { ITaskPic } from '@utils/interfaces/user';

import Minus from '@assets/icons/Minus.svg';

import { PicInfo } from '../list';

type Props = {
  statuses: IStatus[] | null;
  taskTypes: DefaultOptionType[];
  onReset: () => void;
  onSubmit: (values: FieldValues) => void;
  picListInfo: PicInfo[];
  setPicListInfo: React.Dispatch<React.SetStateAction<PicInfo[]>>;
};
export const PrimaryFilter = (props: Props) => {
  const { statuses, taskTypes, onSubmit, picListInfo, setPicListInfo } = props;
  const { t }: Ii18n = useTranslation();
  const { isInternalRole, isExternalRole } = useAuthorization();
  const { customCountryOptions, filterOption } = useOptionsGlobal();
  const [cachedPicListInfo, setCachedPicListInfo] = React.useState<PicInfo[]>(picListInfo);

  const { watch: watchFormContext } = useFormContext<IFormFilter>();
  const watchFormContextPrimary = watchFormContext('primary');
  const methods = useForm({
    defaultValues: watchFormContextPrimary ?? DEFAULT_FILTER_FORM.primary
  });
  const { watch, handleSubmit, reset } = methods;
  const [watchCategory, watchCountries, watchDeadlineFrom, watchDeadlineTo, watchPicIds, watchStatuses] = watch([
    'categoryId',
    'countryIds',
    'deadlineFrom',
    'deadlineTo',
    'picIds',
    'statuses'
  ]);
  // use watch to check because of getValues() not change
  const disableReset = () => {
    const formValues = {
      watchCategory,
      watchCountries,
      watchDeadlineFrom,
      watchDeadlineTo,
      watchPicIds,
      watchStatuses
    };
    return !Object.values(formValues).some((item) => item?.length);
  };

  const convertPicTaskOptions = (pic: ITaskPic) => {
    return {
      ...pic,
      label: !pic.status ? `(${t('account_list:status_account:inactive')})${pic.name}` : pic.name,
      value: pic.picId
    };
  };

  const onSaveFilter: SubmitHandler<IPrimary> = (primary) => {
    setPicListInfo(cachedPicListInfo);
    return onSubmit({
      primary: {
        ...primary,
        categoryId: isExternalRole ? [TASK_TYPE.OTHER] : primary.categoryId
      }
    });
  };

  return (
    <div className='p-5 space-y-[12px] md:w-[620px]'>
      <FormProvider {...methods}>
        <p className='text-xl font-bold capitalize'>{t('button:filter')}</p>
        {isInternalRole && (
          <FormSelect mode='multiple' name='categoryId' label={t('task:type')} placeholder={t('placeholder:select_category')} options={taskTypes} />
        )}
        <FormSelect
          name='countryIds'
          mode='multiple'
          label={t('task:applicant_country')}
          options={customCountryOptions}
          placeholder={t('placeholder:select_country')}
          filterOption={filterOption}
        />
        <div className='space-y-2'>
          <p className='text-base font-medium text-lnk'>{t('task:deadline')}</p>
          <div className='flex items-center gap-4'>
            <FormInputDate
              name='deadlineFrom'
              formatValue='YYYY/MM/DD'
              placeholder={t('placeholder:date')}
              disabledDate={(d) => !!watchDeadlineTo && d.startOf('day').isAfter(watchDeadlineTo)}
            />
            <Minus className='text-textGray shrink-0' />
            <FormInputDate
              name='deadlineTo'
              formatValue='YYYY/MM/DD'
              placeholder={t('placeholder:date')}
              disabledDate={(d) => !!watchDeadlineFrom && d.endOf('day').isBefore(watchDeadlineFrom)}
            />
          </div>
        </div>
        <InfinityFormSelect<ITaskPic, DefaultOptionType>
          mode='multiple'
          name='picIds'
          label={t('task:manager')}
          placeholder={t('placeholder:select_manager')}
          endpoint={API.GET_TASK_PICS}
          convertData={convertPicTaskOptions}
          defaultSelected={watchFormContextPrimary?.picIds ?? []}
          fullSelectedOptions={cachedPicListInfo as any}
          handleChange={(_, options) => {
            const sanitizeOptions: any[] = [];
            options.forEach((op: any) => {
              if (isEmpty(op)) return;
              sanitizeOptions.push({ ...op, id: op?.value, label: op.name });
            });
            setCachedPicListInfo((prev) => [...prev, ...sanitizeOptions]);
          }}
        />
        <FormSelect
          name='statuses'
          mode='multiple'
          options={statuses?.map((status) => ({
            value: status.id,
            label: status.name
          }))}
          label={t('task:status')}
          placeholder={String(t('project:add:please_select_field', { field: t('project:filter:status:label') }))}
        />
        <div className='flex justify-end gap-4'>
          <BaseButton
            type='tertiary'
            size='medium'
            disabled={disableReset()}
            onClick={() =>
              reset({
                ...DEFAULT_FILTER_FORM.primary,
                categoryId: isExternalRole ? [TASK_TYPE.OTHER] : DEFAULT_FILTER_FORM.primary.categoryId
              })
            }
          >
            {t('project:filter:btn_reset')}
          </BaseButton>
          <BaseButton type='primary' size='medium' onClick={handleSubmit(onSaveFilter)}>
            {t('project:filter:btn_aply')}
          </BaseButton>
        </div>
      </FormProvider>
    </div>
  );
};
